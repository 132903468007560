class Apoe2023MarketplaceHelper {
  constructor() {
    this.addNoticeWrapper();
    this.openProductsNewTab();
    this.toggleShippingDestination();
  }

  /**
   * Add a wrapper for WooCommerce notices
   *
   * Woo doesn't always insert a wrapper for notices, so we add one if it doesn't exist.
   *
   * For cosmetics, notice should be at the "top of the page" which means after the cover block and after any adjacent group blocks that have a background.
   * If no cover block (or cover block is not the first block), insert at the top of the main content.
   * If the cover block is not the first element inside .main, insert the notice wrapper as the first element inside .main.
   *
   * @returns {void}
   */
  addNoticeWrapper() {
    const noticesWrapper = document.querySelector('.woocommerce-notices-wrapper');

    if (!noticesWrapper) {
      const main = document.querySelector('.main');
      const wpBlockCover = main.querySelector('div[class^="wp-block-cover"]');
      const newNoticesWrapper = document.createElement('div');
      newNoticesWrapper.className = 'woocommerce-notices-wrapper';

      if (wpBlockCover) {
        if (wpBlockCover !== main.firstElementChild) {
          main.insertAdjacentElement('afterbegin', newNoticesWrapper);
        } else {
          const nextBlock = wpBlockCover.nextElementSibling;

          if (nextBlock && nextBlock.classList.contains('wp-block-group') && nextBlock.classList.contains('has-background')) {
            nextBlock.insertAdjacentElement('afterend', newNoticesWrapper);
          } else {
            wpBlockCover.insertAdjacentElement('afterend', newNoticesWrapper);
          }
        }
      } else {
        main.insertAdjacentElement('afterbegin', newNoticesWrapper);
      }
    }
  }

  openProductsNewTab() {
    const productLinks = document.querySelectorAll('.wc-block-grid__product-link');

    productLinks.forEach(link => {
      link.target = '_blank';
      link.rel = 'noopener noreferrer';
    });
  }

  toggleShippingDestination() {

    // test if cart page
    if (document.querySelector('.woocommerce-cart-form')) {
      const handleShippingMethodChange = () => {
        const selectedShippingMethod = document.querySelector('input[name^="shipping_method"]:checked');

        if (selectedShippingMethod && selectedShippingMethod.value.includes('local_pickup')) {
          const shippingDestination = document.querySelector('.woocommerce-shipping-destination');
          shippingDestination.classList.add('hidden');
        } else {
          const shippingDestination = document.querySelector('.woocommerce-shipping-destination');
          shippingDestination.classList.remove('hidden');
        }
      };

      // reminder: cannot listen for jQuery events using es6 syntax
      jQuery(document.body).on('updated_shipping_method', handleShippingMethodChange);

      // Initial check on page load
      handleShippingMethodChange();
    }
  }
}

export default Apoe2023MarketplaceHelper;

document.addEventListener('updated_shipping_method', (event) => {
  console.log('jpv 2', event);


});
