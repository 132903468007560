/*
 ========Contact Form 7 JS ==========
 */


// Filename: apoe2023_cf7.js

const apoe2023_cf7 = (() => {
  const redirectOnFormSubmit = () => {

    // Register for Health Care Assessment: Redirect to questions after submission
    document.addEventListener('wpcf7mailsent', (event) => {
      if (5752 === event.detail.contactFormId) {
        window.location = '/health-assessment-questions/';
      }
    }, false);
  };

  // Public API
  return {
    init: redirectOnFormSubmit,
  };
})();

export default apoe2023_cf7;

