const apoe2023_navigation = {
  init: function () {
    this.initNavLinks();
    this.initMenuToggle();
    this.initClickOutside();
  },

  initNavLinks: function () {
    const navLinks = document.querySelectorAll('.nav-link');
    navLinks.forEach(navLink => {
      navLink.addEventListener('click', this.clickedForSubmenu);
    });
  },

  initMenuToggle: function () {
    let menuToggle = document.getElementById('menuToggle');

    if (menuToggle) {
      menuToggle.addEventListener('click', () => {
        this.toggleMenus();
      });
    } else {
      console.warn("menuToggle element not found.");
    }
  },

  initClickOutside: function () {
    document.addEventListener('click', (event) => {
      event.composedPath().includes(document.documentElement);
// Assuming you want to close menus when clicking outside of them
      if (!event.target.closest('#menuToggle')) {
        this.closeAllMenus();
      }
    });
  },

  clickedForSubmenu: function (e) {
    if (this.classList.contains('menu-item-has-children')) {
      e.stopPropagation();
      e.preventDefault();
      if (this.classList.contains('active')) {
        apoe2023_navigation.closeAllMenus();
      } else {
        apoe2023_navigation.closeAllMenus();
        if (this.id) {
          apoe2023_navigation.revealSubMenu(this);
        }
      }
    }
  },

  revealSubMenu: function (elem) {
    elem.classList.add('active');
    let submenu = document.querySelector(`#${elem.id} ~ .sub-menu`);
    if (submenu) {
      submenu.classList.add('active');
    }
  },

  closeAllMenus: function () {
    const activeNavLinks = document.querySelectorAll('.nav-link.active');
    const activeSubMenus = document.querySelectorAll('.sub-menu.active');
    activeNavLinks.forEach(activeNavLink => {
      activeNavLink.classList.remove('active');
    });
    activeSubMenus.forEach(submenu => {
      submenu.classList.remove('active');
    });
  },

  toggleMenus: function () {
    let menus = document.getElementById('menus');
    let headerWrapper1 = document.querySelector('.header-wrapper1');


    if (menus) {
      if (!menus.classList.contains('active')) {
        menus.classList.add('active', 'max-h-[600px]', 'opacity-100', 'pointer-events-auto');
        if (headerWrapper1) {
          headerWrapper1.classList.remove('sticky');
          document.documentElement.style.scrollBehavior = 'auto';

          // Delay required for scroll to take effect
          setTimeout(function () {
            window.scrollTo(0, 0);
            document.documentElement.style.scrollBehavior = 'smooth';
          }, 50);

          // Extend the maximum height after the menu is active
          setTimeout(function () {
            menus.classList.add('max-h-none');
          }, 400);
        } else {
          menus.classList.remove('active', 'max-h-none', 'opacity-100', 'pointer-events-auto');
          setTimeout(function () {
            menus.classList.remove('max-h-[600px]');
          }, 20);

          if (headerWrapper1) {
            headerWrapper1.classList.add('sticky');
          }
        }
      } else {
        menus.classList.remove('active', 'max-h-none', 'opacity-100', 'pointer-events-auto');
        setTimeout(function () {
          menus.classList.remove('max-h-[600px]');
        }, 20);

        if (headerWrapper1) {
          headerWrapper1.classList.add('sticky');
        }
      }
    } else {
      console.warn("Menus element not found.");
    }
  },
};

export default apoe2023_navigation;

