/* global apoe2023Woo */

/**
 * @typedef {Object} Apoe2023WooConfig
 * @property {string} [ajax_url]
 * @property {string} [nonce_cart_update]
 */

class Apoe2023Cart {
  constructor() {
    this.cartIconCountElement = document.querySelector('.cart-count');
    this.cartMenuCountElement = document.querySelector('.nav-hdr .sub-menu a[href$="/cart/"]');
    this.ajaxUrl = apoe2023Woo?.ajax_url || '';
    this.nonceValue = apoe2023Woo?.nonce_cart_update || '';
    this.initCartCountUpdate();
  }

  initCartCountUpdate() {
    if ((this.cartIconCountElement || this.cartMenuCountElement) && this.ajaxUrl && this.nonceValue) {
      this.updateCartCount();
      jQuery(document.body).on('wc_cart_button_updated removed_from_cart added_to_cart updated_wc_div updated_cart_totals update_checkout', () => {
        this.updateCartCount();
      });
    }
  }

  updateCartCount() {
    const xhr = new XMLHttpRequest();
    xhr.open('POST', this.ajaxUrl, true);
    xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');

    xhr.onload = () => {
      if (xhr.status >= 200 && xhr.status < 400) {
        const {success, data: cartCount} = JSON.parse(xhr.response);
        if (success) {
          this.cartIconCountElement.textContent = cartCount;
          this.cartIconCountElement.classList.toggle('hidden', cartCount <= 0);
          this.cartIconCountElement.classList.toggle('bg-orange/80', cartCount > 0);

          if (this.cartMenuCountElement) {
            this.cartMenuCountElement.textContent = `Cart ${cartCount > 0 ? `(${cartCount})` : ''}`;
            this.cartMenuCountElement.classList.toggle('font-bold', cartCount > 0);
            this.cartMenuCountElement.classList.toggle('text-2xl', cartCount > 0);
          }
        }
      }
    };

    xhr.onerror = () => {
      console.error('Error updating cart count:', xhr.status);
    };

    const postData = `action=update_cart_count&nonce_cart_update=${encodeURIComponent(this.nonceValue)}`;
    xhr.send(encodeURI(postData));
  }
}

export default Apoe2023Cart;
