// Import domReady package from the Sage framework
import domReady from '@roots/sage/client/dom-ready';
import {apoe2023Globals} from "@scripts/apoe2023_globals.js";
// import apoe2023_modal from "@scripts/apoe2023_modal.js";
import apoe2023_scrolling from "@scripts/apoe2023_scrolling.js";
import apoe2023_navigation from "@scripts/apoe2023_navigation.js";
import apoe2023_cf7 from "@scripts/apoe2023_cf7.js";
import Apoe2023Cart from './apoe2023_cart.js';
import apoe2023_Donation_Product from "./apoe2023_donations.js";
import Apoe2023MarketplaceHelper from "./apoe2023_marketplace_helper.js";

/**
 * Main entry point for the application
 */
domReady(async () => {


  /**
   * Update the port in development environment for anchor links.
   * This is useful when your dev setup is running on a different port.
   */
  const jpv_addDevPortToLinks__to_be_removed = () => {
    // count number of links changed, and log it
    let count = 0;
    const links = document.getElementsByTagName('a');
    for (let i = 0; i < links.length; i++) {
      const link = links[i];
      const href = link.href;
      if (href.startsWith('http://localhost/')) {
        link.href = href.replace(/(http:\/\/localhost)/, "$1:3322");
        count++;
        // console.log(href, ' --> ', link.href);
      }
      if (href.startsWith('http://192.168.2.25/')) {
        link.href = href.replace(/(http:\/\/192.168.2.25)/, "http://localhost:3322");
        count++;
      }
    }
    console.log('jpv_addDevPortToLinks__to_be_removed() changed ' + count + ' links');
  };

  // Execute the function to update the links
  // Check if running on local development environment
  if (window.location.hostname === 'localhost') {

    jpv_addDevPortToLinks__to_be_removed();
  }
  /*
    function jpv__apoe2023__logMainLinks() {
      console.log('jpv__apoe2023__logMainLinks() -- dev, to be removed', 'logging links in #main');
      let totalLinksCount = 0; // Initialize a counter for the total number of links
      const mainElement = document.querySelector('#main');
      if (!mainElement) {
        console.log('No #main element found.');
        return;
      }

      const links = mainElement.querySelectorAll('a:not(.apoe2023-paid-bottom-nav a, .apoe-nav-arrows a, .mobile-premium-page-prev-next a)');
      if (links.length === 0) {
        console.log('No links found in #main.');
        return;
      }

      totalLinksCount += links.length; // Update the counter with the number of links found in the current element
      links.forEach((link, index) => {
        console.log(`Link ${index + 1}: ${link.textContent.trim()}`);
        console.log(` - href: ${link.href}`);
      });


      console.log(`Total links found: ${totalLinksCount}`); // Log the total number of links found
    }*/

// Execute the function to log the links
//   jpv__apoe2023__logMainLinks();


  apoe2023_scrolling.init(apoe2023Globals.screens.md, 200);


  // Initialize navigation controls
  apoe2023_navigation.init();

  // Initialize contact forms 7 JS
  apoe2023_cf7.init();

  /**
   * Play the video when its wrapper is clicked and show the video controls.
   */
  document.querySelectorAll('.front-video-wrapper').forEach(element => {
    element.addEventListener('click', () => {
      if (!element.classList.contains('playing')) {
        element.classList.add('playing');
        element.querySelector('.video-text').classList.add('hidden');
        let videoElement = element.querySelector('video');
        videoElement.style.removeProperty('display'); // had to hard-code display:none for loading
        videoElement.classList.remove('hidden');
        videoElement.play();
      }
    });
  });


  // Get all .post-item containers
  const containers = document.querySelectorAll('.post-block');

  containers.forEach(container => {
    // Add hover state to all <a> when any one of them is hovered
    container.addEventListener('mouseover', function (event) {
      // don't confuse user if mousing over non-clickable item
      if (event.target !== container) {
        container.querySelectorAll('a').forEach(a => a.classList.add('hover-state'));
      }
    });

    // Remove hover state from all <a> when the mouse leaves any of them
    container.addEventListener('mouseout', function () {

      container.querySelectorAll('a').forEach(a => a.classList.remove('hover-state'));

    });
  });


  const handleSquareGridCoverBlocks = () => {
    const coverBlocks = document.querySelectorAll('.square-covers1 .wp-block-cover');

    coverBlocks.forEach(coverBlock => {
      // Find the <a> tag inside the cover block
      const link = coverBlock.querySelector('h3 a');
      if (link) {
        // Create a new anchor element to wrap around the cover block
        const newLink = document.createElement('a');
        newLink.href = link.href;
        newLink.className = 'square-cover-link';

        // Insert the new link in the DOM, wrapping the cover block
        coverBlock.parentNode.insertBefore(newLink, coverBlock);
        newLink.appendChild(coverBlock);

        // Replace the original link with its content
        link.outerHTML = link.innerHTML;

        // Add event listeners to toggle 'hover' class
        newLink.addEventListener('mouseenter', () => {
          newLink.classList.add('hover');
        });
        newLink.addEventListener('mouseleave', () => {
          newLink.classList.remove('hover');
        });
      }
    });

  }

  handleSquareGridCoverBlocks();

  const handleProductItemCards = () => {
    // Find all li.product elements
    const products = document.querySelectorAll('li.type-product');

    products.forEach(product => {
      // Find the woocommerce-LoopProduct-link inside the product
      const productLink = product.querySelector('a.woocommerce-LoopProduct-link');
      const productCardTop = product.querySelector('.product-card-top');

      if (productLink && productCardTop) {
        const href = productLink.getAttribute('href');

        // Add click event listener
        productCardTop.addEventListener('click', (event) => {
          // Prevent default if it's not a click on the 'Add to cart' button
          if (!event.target.classList.contains('add_to_cart_button')) {
            event.preventDefault();
            window.location.href = href;
          }
        });

        // Add hover event listeners
        product.addEventListener('mouseenter', () => product.classList.add('hover'));
        product.addEventListener('mouseleave', () => product.classList.remove('hover'));
      } else {
        console.warn('Product link or card top not found.');
        console.log(productLink);
        console.log(productCardTop);
      }
    });

  }

  handleProductItemCards();

  apoe2023Globals.domReady(() => {
    const goBackElements = document.querySelectorAll('.apoe2023__goBackLink, .apoe2023__goBackButton');
    goBackElements.forEach(element => element.addEventListener('click', event => {
      event.preventDefault();
      window.history.back();
    }));
  });


  /**
   * Handle the 'Previous Page' button
   *
   * Displays back button when the user is coming from a premium week content page,
   * and current page is premium content, but not week.
   *
   */

  const previousUrl = document.referrer;
  const currentUrl = window.location.href;

  if (previousUrl.includes('premium-content/week-') && currentUrl.includes('premium-content') && !currentUrl.includes('premium-content/week-')) {
    const mainElement = document.getElementById('main');
    if (mainElement) {
      const buttonHTML = '<p><button onclick="history.back()" class="js-prev-page-btn mt-5 w-auto bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">Previous Page</button></p>';
      mainElement.insertAdjacentHTML('afterbegin', buttonHTML);
      mainElement.insertAdjacentHTML('beforeend', buttonHTML);
    }
  }


  /**
   * Handle the 'case studies' page (top buttons scroll to corresponding section below)
   * may want to make it more generic and re-use on other similar pages
   */
    // Select all .wp-block-cover elements within #caseStudiesTop
  const caseStudyCovers = document.querySelectorAll('#caseStudiesTop .wp-block-cover');

// Add event listeners to each .wp-block-cover element
  caseStudyCovers.forEach((cover, index) => {
    cover.addEventListener('mouseenter', () => {
      cover.style.cursor = 'pointer';
      cover.classList.add('hover');
    });

    cover.addEventListener('mouseleave', () => {
      cover.style.cursor = 'default';
      cover.classList.remove('hover');
    });

    cover.addEventListener('click', () => {
      const caseStudyMain = document.querySelector('.case-studies-main');
      const targetHeading = caseStudyMain.querySelectorAll('.wp-block-columns')[index];

      if (targetHeading) {
        apoe2023_scrolling.scrollToElement(targetHeading);
      }
    });
  });

  new Apoe2023MarketplaceHelper();
  new Apoe2023Cart();

  //delay the initialization of the donations class to allow the cart to be initialized first
  setTimeout(() => {
    // Find all donation product containers and instantiate the class for each one
    const donationProductRoots = document.querySelectorAll('.main .product_cat-foundation-donation');
    donationProductRoots.forEach(root => new apoe2023_Donation_Product(root));

  }, 500);


});

/**
 * Webpack Hot Module Replacement API
 *
 * see {@link https://webpack.js.org/api/hot-module-replacement/}
 *
 * @param {{webpackHot:string}} data
 */
if (import.meta.webpackHot) import.meta.webpackHot.accept(console.error);





