import {apoe2023Globals} from "@scripts/apoe2023_globals.js";

/**
 * apoe2023_scrolling module.
 * @module apoe2023_scrolling
 */

const apoe2023_scrolling = {

  header: document.querySelector(".header-wrapper1"),
  headerHeight: -1, // static header, ensure user can see the target element

  /**
   * Initializes the general scrolling functionality.
   * @param {number} breakpoint - The breakpoint for responsive design.
   * @param {number} debounceDelay - The delay for the debounce function.
   */
  init: function (breakpoint, debounceDelay) {
    this.initScrolling(debounceDelay);
    this.initBackToTop();
    this.initGeneralScreenWidthChanges(debounceDelay);
    // Wait for the window to load before initializing anchor link scrolling
    apoe2023Globals.assetsLoaded(() => {
      this.initAnchorLinkScrolling();
    });
  },

  /**
   * Initializes the scrolling functionality.
   * @param {number} debounceDelay - The delay for the debounce function.
   */
  initScrolling: function (debounceDelay) {
    const scrollDnIndicator = document.querySelector(".scroll-indicator");
    const pdMobileScrollDnIndicator = document.querySelector(".mobile-premium-page-prev-next .more");

    window.addEventListener('scroll', apoe2023Globals.debounce(() => {
      this.handleScrollActions(scrollDnIndicator, pdMobileScrollDnIndicator);
    }, debounceDelay));

    this.addClickEvent(scrollDnIndicator, 0.75);  // 75% of window height
    this.addClickEvent(pdMobileScrollDnIndicator, 0.75);  // 75% of window height
  },

  /**
   * Handles scroll actions.
   * @param {Element} scrollDnIndicator - The scroll down indicator element.
   * @param {Element} pdMobileScrollDnIndicator - The mobile scroll down indicator element.
   */
  handleScrollActions: function (scrollDnIndicator, pdMobileScrollDnIndicator) {
    const isScrolled = window.scrollY + window.innerHeight >= document.body.offsetHeight;
    this.updateScrollDnIndicator(scrollDnIndicator, isScrolled);
    this.updateMoreButton(pdMobileScrollDnIndicator);
    this.handleMobileScroll();
  },

  /**
   * Handles mobile scroll.
   */
  handleMobileScroll: function () {
    const backToTopWrapper = document.querySelector("#back-to-top-wrapper");
    backToTopWrapper?.classList.toggle("opacity-90", window.scrollY > 300);
    backToTopWrapper?.classList.toggle("pointer-events-auto", window.scrollY > 300);
  },

  /**
   * Updates the more button.
   * @param  moreButton - The more button element.
   */
  updateMoreButton: function (moreButton) {
    if (moreButton) {
      moreButton.style.display = window.scrollY + window.innerHeight >= document.body.offsetHeight ? 'none' : 'flex';
    }
  },

  /**
   * Initializes the back to top functionality.
   */
  initBackToTop: function () {
    const backToTopButton = document.getElementById("back-to-top-wrapper");
    backToTopButton?.addEventListener("click", () => window.scrollTo({
      top: 0,
      behavior: "smooth",
    }));
  },

  /**
   * Initializes the general screen width changes.
   * @param {number} debounceDelay - The delay for the debounce function.
   */
  initGeneralScreenWidthChanges: function (debounceDelay) {
    const generalScreenWidthChanges = () => window.dispatchEvent(new Event('scroll'));
    window.addEventListener("load", apoe2023Globals.debounce(generalScreenWidthChanges, debounceDelay));
    window.addEventListener("orientationchange", apoe2023Globals.debounce(generalScreenWidthChanges, debounceDelay));
    window.addEventListener("resize", apoe2023Globals.debounce(generalScreenWidthChanges, debounceDelay));
  },

  /**
   * Adds a click event to an element.
   * @param {Element} element - The element to add the click event to.
   * @param {number} scrollFraction - The fraction of the window height to scroll by.
   */
  addClickEvent: function (element, scrollFraction) {
    element?.addEventListener('click', () => window.scrollBy({
      top: window.innerHeight * scrollFraction,
      behavior: 'smooth',
    }));
  },

  /**
   * Updates the scroll down indicator.
   * @param  indicator - The scroll down indicator element.
   * @param {boolean} isScrolled - Whether the page is scrolled or not.
   */
  updateScrollDnIndicator: function (indicator, isScrolled) {
    indicator?.classList.toggle("opacity-90", !isScrolled);
    indicator?.classList.toggle("pointer-events-auto", !isScrolled);
    if (window.scrollY > 0) indicator?.classList.remove("animate");
    else indicator?.classList.add("animate");
  },

  initAnchorLinkScrolling: function () {
    // Initializes the anchor link scrolling offset.
    this.headerHeight = this.header ? this.header.offsetHeight : 0;
    this.headerHeight += 10; // Add a little extra padding

    // Handle click events on anchor tags
    document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
      anchor.addEventListener("click", (e) => {
        e.preventDefault();
        const targetId = anchor.getAttribute("href").substring(1);
        this.scrollToElementById(targetId);
      });
    });

    // Handle page load with an anchor tag in the URL
    const url = new URL(window.location.href);
    const targetId = url.hash.substring(1); // remove the '#' from the hash
    if (targetId) {
      this.scrollToElementById(targetId);
    }
  },

  scrollToElementById: function (id) {
    const targetElement = document.getElementById(id);
    if (targetElement) {
      this.scrollToElement(targetElement);
    }
  },

  scrollToElement: function (element) {
    if (element) {
      const targetPosition = element.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({
        top: targetPosition - this.headerHeight,
        behavior: "smooth",
      });
    }

  },


};


export default apoe2023_scrolling;
