class apoe2023_Donation_Product {
  constructor(root) {
    this.root = root;
    this.customPriceInput = this.root.querySelector('#custom-price');
    this.addToCartButton = this.root.querySelector('.single_add_to_cart_button[name="add-to-cart"]');
    this.predefinedPriceButtons = this.root.querySelectorAll('.predefined-price');
    this.priceElement = this.root.querySelector('.woocommerce-Price-amount.amount');
    this.otherPriceButton = this.root.querySelector('.other-price-button');

    // Fail gracefully if any required element is not found
    // presumably for partially displayed donation products
    if (!this.customPriceInput || !this.addToCartButton || !this.predefinedPriceButtons.length || !this.priceElement || !this.otherPriceButton) {
      // console.log('One or more required elements not found. Skipping initialization for this donation product.');
      return;
    }

    this.handleCustomPriceInput();
    this.handlePredefinedPriceButtons();
    this.handleOtherPriceButton();

  }

  handleCustomPriceInput() {
    this.customPriceInput.addEventListener('input', (event) => {
      const customPrice = parseFloat(event.target.value);
      if (isNaN(customPrice) || customPrice < 0) return;

      this.addToCartButton.setAttribute('data-product_price', customPrice.toString());
      this.updateDisplayedPrice(customPrice);
    });
  }

  handlePredefinedPriceButtons() {
    this.predefinedPriceButtons.forEach((button) => {
      button.addEventListener('click', (event) => {
        this.predefinedPriceButtons.forEach(btn => btn.classList.remove('active'));
        event.target.classList.add('active');

        this.customPriceInput.value = parseFloat(event.target.getAttribute('data-price')).toFixed(2);
        this.customPriceInput.dispatchEvent(new Event('input'));

        this.otherPriceButton.classList.remove('active');
        this.customPriceInput.parentElement.classList.remove('max-h-20');
        this.customPriceInput.parentElement.classList.add('max-h-0');
      });
    });
  }

  handleOtherPriceButton() {
    this.otherPriceButton.addEventListener('click', () => {
      this.predefinedPriceButtons.forEach(btn => btn.classList.remove('active'));
      this.otherPriceButton.classList.toggle('active');
      this.customPriceInput.parentElement.classList.toggle('max-h-0');
      this.customPriceInput.parentElement.classList.toggle('max-h-20');
    });
  }

  updateDisplayedPrice(price) {
    this.priceElement.textContent = this.formatPrice(price);
  }

  formatPrice(price) {
    const formattedPrice = price.toFixed(2);
    return `$${formattedPrice}`;
  }
}

export default apoe2023_Donation_Product;
